<template>
  <b-modal 
    v-model="show"
    class="text-center"
    centered
    :title="$t('settings.social.titleSpotify')"
    hide-footer
  >
    <b-card no-body class="no-shadow">
      <b-card-body>
        <b-card-text>{{$t('settings.social.consentEmail')}}</b-card-text>
        <div class="business-items">
          <div class="business-item">
            <div class="d-flex align-items-center justify-content-center">
              <b-form-checkbox v-model="check_email" class="checkbox-pointer">
                {{$t('settings.social.checkboxConsentEmail')}}
              </b-form-checkbox>
            </div>
          </div>
        </div>

        <b-card-text class="mt-3">{{$t('settings.social.consentAccount')}}</b-card-text>
        <div class="business-items">
          <div class="business-item">
            <div class="d-flex align-items-center justify-content-center">
              <b-form-checkbox v-model="check_account" class="checkbox-pointer">
                {{$t('settings.social.checkboxConsentAccount')}}
              </b-form-checkbox>
            </div>
          </div>
        </div>

      </b-card-body>
    </b-card>

    <b-row>
      <b-col cols="12" class="text-right">
        <b-button variant="primary" @click="connectSpotify()" :disabled="checkButton()">{{$t('settings.social.link_network')}}</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import { BModal, BButton, BRow, BCol, BCard, BCardBody, BCardText, BFormCheckbox } from 'bootstrap-vue'

export default {
  name: 'ConsentSpotify',
  props: {
    modalShow: {
      type: Boolean,
      default: false
    },
  },
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BFormCheckbox
  },
  data () {
    return {
      show: this.modalShow,
      check_email: false,
      check_account: false
    }
  },
  created() {
  },
  methods: {
    checkButton() {
      return !this.check_email || !this.check_account
    },
    connectSpotify() {
      this.$emit('loginSpotify')
    }
  }
}
</script>

<style>
  .no-shadow {
    box-shadow: none;
  }
  .checkbox-pointer label {
    cursor: pointer !important;
  }
</style>